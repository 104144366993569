import _ from 'underscore';
import extend from 'extend';
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'

import consumer_t_od_hotel_order_interim_hotelOrderBookingDetail
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelOrderBookingDetail'//获取订单信息
import consumer_t_od_hotel_order_interim_calculateHotelPersonPrice
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_calculateHotelPersonPrice'//计算总价
import consumer_t_od_hotel_order_interim_createOrderOnPerson
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_createOrderOnPerson' //创建订单
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息

import CommonlyUsedPassenger
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/commonly-used-passenger/1.0.1/index.vue"//常旅客组件

import BackBtn from '@/component/backBtn/index.vue'
import moment from "moment";
//返回按钮

export default {
    data() {
        return {
            loading: false,
            submitting: false,
            curUser: {},
            orderNo: '',
            priceResult: 0,
            hotelOrderInfo: {
                hotelPicUrl: ''
            },
            form: {
                arrivalTime: '13:00',
                remarks: '',
                linkman: '',
                linkmanPhone: '',
                smsType: 1,
                linkmanSms: 1,
                userIdList: [],
                userList: [],
                contact: [],
                roomAmount: 0,
                roomList: [{
                    roomId: 1,
                    user: {
                        certificateTypeList: []
                    }
                }]
            },
            pickerOptions: {start: '13:00',step: '01:00',end: '24:00'},
            roomAmountList: [],
            curUserCertificateType: 0,//当前登录用户选中的证件类型
            curUserCertificateText: '',//当前登录用户选中的证件名称
            curUserCertificateValue: '',//当前登录用户选中的证件号码
            curUserCertificateTypeList: [],//当前登录用户可用的证件list
            certificateType: 0,//可选证件list中默认选中的证件类型
            certificateText: '',//可选证件list中默认选中的证件名称
            certificateTypeList: [],//可选证件list中默认选中的证件名称
            curRoomIndex: 0,//当前需要更改证件信息的房间index
            curCertificateTypeList: [],//当前需要更改证件信息的证件类型列表
            choiceCardType: {},//证件类型弹框中选中的证件类型
            today: '',
            isTodayMorning: false,
            showNotBookDialog: false,
        }
    },
    components: {
        BackBtn,
        CommonlyUsedPassenger
    },
    async activated() {
        this.init();
    },
    watch: {
        //房间数量改变时，改变需要选定入住人数和预定总价
        'form.roomAmount'(newVal) {
            this.form.roomList = [{
                roomId: 1,
                user: {
                    personName: this.curUser.realName + '(本人)',
                    certificateType: this.curUserCertificateType,
                    certificateValue: this.curUserCertificateValue,
                    certificateTypeText: this.certificateTypeText,
                    certificateTypeList: this.curUserCertificateTypeList,
                }
            }];
            for (let i = 1; i < newVal; i++) {
                const p = {
                    roomId: i + 1,
                    user: {
                        personName: '',
                        certificateType: this.certificateType,
                        certificateValue: '',
                        certificateTypeText: this.certificateTypeText,
                        certificateTypeList: this.certificateTypeList,
                    }
                };
                const o = extend(true, {}, p);
                // const o = extend({},p);

                this.form.roomList.push(o)
            }
            this.getAllPrice();
        },
    },
    methods: {
        //初始化
        async init() {
            this.loading = true;
            this.orderNo = this.$route.query.orderNo;
            this.form.roomAmount = 1;
            await this.getOrderInfo();
            this.initSelectorData();
            this.evectionType = parseInt(this.$route.query.evectionType)
            await this.getCurUser();
            this.setDefaultVal();
            this.loading = false;
        },

        //获取订单信息
        async getOrderInfo() {
            let res = await consumer_t_od_hotel_order_interim_hotelOrderBookingDetail({orderNo: this.orderNo});
            this.hotelOrderInfo = res.datas;
        },

        //初始化选择组件
        initSelectorData() {
            this.getContactSingleSelectorData();
        },

        //初始化数据
        setDefaultVal() {
            this.roomAmountList = [];
            for (let i = 1; i < 9; i++) {
                this.roomAmountList.push(i);
            }
            this.form.roomList[0].user.personName = this.curUser.realName + '(本人)';

            let certificateTypeList = this.hotelOrderInfo.certificateResults;
            certificateTypeList.forEach(item => {
                this.$set(item, 'certificateValue', '');
            })
            if (this.hotelOrderInfo.needCertificate === 0 &&
                this.curUser.certificates && this.curUser.certificates.length > 0 &&
                certificateTypeList && certificateTypeList.length > 0
            ) {
                //该酒店可选的证件list
                this.certificateTypeList = certificateTypeList;
                let certificateObj = certificateTypeList.find(item => {
                    return item.certificateType === 1;
                })
                if (!certificateObj) {
                    certificateObj = certificateTypeList[0];
                }
                this.certificateTypeList.forEach(item => {
                    this.$set(item, 'isSelected', false);
                    if (item.certificateType === certificateObj.certificateType) {
                        this.$set(item, 'isSelected', true);
                        this.certificateType = item.certificateType;
                        this.certificateTypeText = item.certificateTypeText;
                    }
                })

                //在当前登录人证件list和该酒店可选的证件list中寻找当前登录人默认选中的证件信息
                const certificateTypeList1 = certificateTypeList.map(item => {
                    return item.certificateType;
                });
                const certificateTypeList2 = this.curUser.certificates.map(item => {
                    return item.certificateType;
                });
                const certificateTypeList3 = _.intersection(certificateTypeList1, certificateTypeList2);
                const certificateTypeList4 = this.curUser.certificates.filter(function (item) {
                    const certificateType = item.certificateType;
                    return _.contains(certificateTypeList3, certificateType);
                });
                let certificateObj1 = certificateTypeList4.find(item => {
                    return item.certificateType === 1;
                })
                if (!certificateObj1) {
                    certificateObj1 = certificateTypeList4[0];
                }
                let certificateObj2 = certificateTypeList.find(item => {
                    return item.certificateType === certificateObj1.certificateType;
                })
                this.curUserCertificateType = certificateObj1.certificateType;
                this.curUserCertificateText = certificateObj2.certificateTypeText;
                this.curUserCertificateValue = certificateObj1.certificateValue;
                this.form.roomList[0].user.certificateType = certificateObj1.certificateType;
                this.form.roomList[0].user.certificateTypeText = certificateObj2.certificateTypeText;
                this.form.roomList[0].user.certificateValue = certificateObj1.certificateValue;

                certificateTypeList.forEach(item => {
                    this.$set(item, 'isSelected', false);
                    if (item.certificateType === certificateObj1.certificateType) {
                        this.$set(item, 'isSelected', true);
                    }
                })

                const oCertificateTypeList = extend(true, [], certificateTypeList);
                oCertificateTypeList.forEach(item => {
                    let certificate = this.curUser.certificates.find(card => {
                        return card.certificateType === item.certificateType
                    })
                    if (certificate) {
                        item.certificateValue = certificate.certificateValue;
                    }
                });

                // 判断入住时间
                this.today = this.$moment().format('YYYY-MM-DD');
                let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
                this.isTodayMorning = this.hotelOrderInfo.checkInDate === yesterday ? true : false;
                if (this.isTodayMorning) {
                    //当天凌晨入住
                    let hour = 1;
                    if (moment().hour() > 1) {
                        hour = moment().hour() + 1;
                    }
                    this.form.arrivalTime = `${hour}:00`;
                    this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '06:00'}
                } else {
                    if (this.hotelOrderInfo.checkInDate === moment().format('YYYY-MM-DD')) {
                        let hour = 13;
                        if (moment().hour() > 13 || (moment().hour() === 13 && moment().minute() > 0)) {
                            hour = moment().hour() + 1;
                        }
                        this.form.arrivalTime = `${hour}:00`;
                        this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '24:00'}
                    } else {
                        this.form.arrivalTime = '13:00';
                        this.pickerOptions = {start: '13:00',step: '01:00',end: '24:00'}
                    }
                }

                this.curUserCertificateTypeList = oCertificateTypeList;
                this.form.roomList[0].user.certificateTypeList = oCertificateTypeList;
            }

            this.form.linkman = this.curUser.realName + '(本人)';
            this.form.linkmanPhone = this.curUser.phone;
        },

        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
            this.form.linkman = this.curUser.realName + '(本人)';
            this.form.linkmanPhone = this.curUser.phone;
        },

        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            const _this = this;
            this.$refs.aCommonlyUsedPassenger.init({
                type: '100',
                btn_contacts_click_event_handler(args) {
                    //选中的联系人信息
                    const contacts = args.user_list;
                    let name = contacts.frequentPassengerName;
                    if (contacts.operator === 1) {
                        name += '(本人)'
                    }
                    _this.form.linkman = name;
                    _this.form.linkmanPhone = contacts.phone;
                },
            })
        },

        //改变选中的证件类型
        changeCertificate(room) {
            let certificate = room.user.certificateTypeList.find(item => {
                return item.certificateType === room.user.certificateType;
            });
            room.user.certificateTypeText = certificate.certificateTypeText;
            room.user.certificateValue = certificate.certificateValue
        },

        //选择联系人
        openContactPopup() {
            this.$refs.aCommonlyUsedPassenger.showContactsPopup();
        },

        // 计算订单价格
        async getAllPrice() {
            let params = {
                orderNo: this.orderNo,
                headCounts: this.form.roomAmount,
                roomNum: this.form.roomAmount
            }
            let res = await consumer_t_od_hotel_order_interim_calculateHotelPersonPrice(params)
            this.priceResult = res.datas
        },

        //关闭没有可预订房间的弹框，并返回
        onCloseDialog() {
            this.showNotBookDialog = false;
            this.$router.back();
        },

        //创建订单
        async createOrder() {
            let linkman = this.form.linkman.replace('(本人)', '').trim();
            let params = {
                orderNo: this.orderNo,
                linkman: linkman,
                linkmanPhone: this.form.linkmanPhone,
                linkmanSms: this.form.linkmanSms,
                roomNum: this.form.roomAmount,
                latestArrivalTime: this.form.arrivalTime,
                hotelRemark: this.form.remarks,
                orderAmount: this.priceResult.sum,
                personResults: this.form.roomList.map(item => {
                    let personName = item.user.personName.replace('(本人)', '').trim();
                    item.user.personName = personName;
                    return item.user
                }),
            };
            let [err, result] = await awaitWrap(consumer_t_od_hotel_order_interim_createOrderOnPerson(params));
            if (err) {
                return false
            }
            let canSubmit = result.datas.isSuccess;
            return canSubmit;
        },

        //检查是否可提交
        checkForm() {
            let canSubmit = false;

            //必填-到店时间
            if (!this.form.arrivalTime) {
                this.$message({
                    type: 'warning',
                    message: '请选择到店时间'
                })
                return canSubmit;
            }

            //必填-入住人
            let user = {};
            this.form.userList = [];
            this.form.roomList.forEach(item => {
                if (item.user.personName) {
                    let personName = item.user.personName.replace('(本人)', '').trim();
                    user = {
                        personName: personName,
                        certificateType: item.user.certificateType,
                        certificateValue: item.user.certificateValue
                    };
                    this.form.userList.push(user);
                }
            })
            if (!this.form.userList || this.form.userList.length !== this.form.roomList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先添加入住人'
                })
                return canSubmit;
            }

            //必填-入住人证件信息
            if (this.hotelOrderInfo.needCertificate === 0) {
                let noCertificateUser = [];
                this.form.roomList.forEach(item => {
                    if (!item.user.certificateValue) {
                        noCertificateUser.push(item.user);
                    }
                })
                if (noCertificateUser && noCertificateUser.length > 0) {
                    this.$message({
                        type: 'warning',
                        message: '请先完善入住人证件信息'
                    })
                    return canSubmit;
                } else {
                    let userList = _.uniq(_.pluck(this.form.userList, 'certificateValue'));
                    if (userList.length !== this.form.userList.length) {
                        this.$message({
                            type: 'warning',
                            message: '入住人不可选择同一人'
                        })
                        return canSubmit;
                    }
                }
            }

            //必填-联系人
            if (this.form.linkman === '' || this.form.linkmanPhone === '') {
                this.$message({
                    type: 'warning',
                    message: '请先填写联系人'
                })
                return canSubmit;
            }

            canSubmit = true;
            return canSubmit;
        },

        //提交
        async submit() {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    this.showNotBookDialog = true;
                    return;
                }
            }

            if (this.checkForm()) {
                this.submitting = true;
                const loading = this.$loading({
                    lock: true,
                    text: '创建订单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                //创建订单
                let createOrderResult = await this.createOrder();
                if (!createOrderResult) {
                    this.submitting = false;
                    this.$message({
                        type: 'warning',
                        message: '创建订单失败，请重新选择'
                    })
                    loading.close();
                    return
                }
                loading.close();
                this.submitting = false;
                this.$router.push({
                    name: 'admin-personal-hotel-submit-order',
                    query: {
                        orderNo: this.orderNo
                    }
                })
            }
        },
    }
}
